.about-content {
  color: #653399;
  overflow: hidden;
}

.mv-container {
  display: flex;
}

.about-us .preorder-section {
  padding: 2rem 0 2rem 0;
}

.about-us .featured-section {
  padding: 0;
}

.about-us {
  margin: 2rem !important;
}

.about-us .investor-relations-card {
  width: auto;
}
.about-images .small-image {
  width: 93%;
  border-radius: 17px;
}

.about-us .main-image {
  border-radius: 17px;
}

.about-images .stacked-images{
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .about-us .investor-relations-container {
     padding: 0;
  }
  .about-images .small-image {
    width: 100% !important;
    max-width: 100%;
  }

  .about-images .stacked-images{
    flex-direction: column;
  }
  .about-us {
    margin: 1rem !important;
  }
  
   
}
