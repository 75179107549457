.investors-container {
    position: relative;
    padding: 2rem;
    overflow: hidden;
    z-index: 10;
  }
  

  .Investor-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .Investor-header img {
    width: 300px;
    height: 400px;
    z-index: 2;
  }
  .section h2 {
    margin-bottom: 1rem;
    position: relative;
    z-index: 2;
  }
  
  .section p, .section ul {
    font-size: 1rem;
    line-height: 1.5;
    z-index: 2;
  }
  
  .reasons {
    display: flex;
    flex-wrap: wrap;
    gap: 20px
  }
  
  .reason {
    border-radius: 30px;
    flex: 1;
    padding: 1rem;
    min-width: 250px;
    position: relative;
    z-index: 2;
    background-color: #fff;
    margin: 10px;
    border: 1px solid var(--active-color);
  }
  
  .reason h3 {
    margin-top: 0.5rem;
    font-size: 1.2rem;
  }
  
  .reason p {
    font-size: 1rem;
  }
  
  .icon-wrapper {
    display: inline-block;
    padding: 8px 10px;
    background-color: #65339a;
    border-radius: 8px;
    transform: rotate(45deg);
  }
  
  .preorder-page .icon,
  .investors-container .icon {
    font-size: 1rem;
    color: #ceaff0;
    transform: rotate(-45deg);
  }

  .investors-container .feature-title {
    margin-bottom: 10px;
  }
  
  .investors-container .image {
    width: 100%;
    border-radius: 8px;
    margin: 1rem 0;
    box-shadow: 0 4px 8px rgba(240, 118, 201, 0.1);
    z-index: 2;
  }
  
  .team-section {
    text-align: center;
    padding: 50px 0;
  }
  
  .team-heading {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #653399;
  }
  
  .team-members {
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
  }
  
  .team-member {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 250px;
    text-align: center
  }

  .team-member h3,
  .team-member p {
    padding: 0;
    margin: 0;
  }
  
  .image-container {
    position: relative;
    width: 140px;
    height: 140px;
    margin: 0 auto;
  }
  
  .team-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid #653399;
  }
  
  .about-us .linkedin-icon,
  .investors-container .linkedin-icon {
    position: absolute;
    bottom: -5px;
    right: -10px;
    background-color: #EB4913;
    color: #fff;
    border-radius: 50%;
    padding: 5px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .investors-container h3 {
    font-size: 1rem;
    font-weight: 600;
  }
  
  .position {
    font-size: 1rem;
    color: #653399;
    font-weight: bold;
    margin: 0;
  }
  
  .investors-container p {
    color: #666;
    padding: 0 10px;
  }
  
  
  .funding.section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .heading-with-icon {
    flex: 1;
    min-width: 300px;
  }

  .investor-icon {
    font-size: 1rem;
    color: #ceaff0;
    transform: rotate(-45deg);
  }
  
  .investors-container .funding-icon {
    padding: 30px;
    opacity: 0.2;
    margin: 0 20px;
    transform: rotate(40deg);
    width: 120px; 
    height: 120px; 
    border-radius: 50px; 
    background-color: #653399; 
    color: #ceaff0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .investor-relations-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 40px;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }
  
  .investor-relations-card {
    width: 300px;
    padding: 20px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
  }

  
  @media (max-width: 768px) {

    .reason {
        margin: 10px 0;
    }
    .funding.section {
      flex-direction: column;
      align-items: flex-start;
    }
  
      
    .funding-icon {
      margin-top: 20px;
      margin-right: 0;
    }
    .investor-relations-container {
        flex-direction: column;
        background: none;
        box-shadow: none;
    }
  }
  .download-pitch-deck {
    text-align: center;
  }
  
  .pitch-deck-button {
    display: inline-flex;
    align-items: center;
    padding: 12px 20px;
    background-color: #653399;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    border-radius: 12px;
    transition: background-color 0.3s ease;
  }
  
  .pitch-deck-button:hover {
    background-color: #52317a;
  }
  
  .investor-relations-container .download-icon {
    margin-right: 8px;
    font-size: 1.2rem;
  }

  .investors-container .heading-with-icon {
    background-color: rgb(215 79 245 / 10%);
    border-radius: 10px;
    padding: 20px;
  }

  .investors-container .funding {
    display: flex;
    margin: 50px 0px;
    justify-content: center;
    gap: 30px;
    z-index: 2;
  }
  

  .investors-container p,
  .investors-container h1,
  .investors-container h2,
  .investors-container h3 {
    margin: 0;
  }

  .investors-container .titile-icon {
    display: flex;
    gap: 20px;
    margin: 10px 0;
    align-items: center;
    
  }

   .reason h3 {
    font-size: 20px;
    line-height: 36px;
  }

  .investors-container .feature-item {
    padding: 30px;
    background-color: #fff;
  }

  .investors-container .feature-list {
    margin: 60px 0;
  }
  
  .shapes {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  .shape {
    position: absolute;
    background: #653399;
    opacity: 0.1;
    border-radius: 0;
  }
  
  .shape1 {
    width: 200px;
    height: 200px;
    top: 10%;
    left: 5%;
    transform: rotate(45deg);
  }
  
  .shape2 {
    width: 300px;
    height: 300px;
    top: 50%;
    left: 60%;
    transform: rotate(30deg);
  }
  
  .shape3 {
    width: 150px;
    height: 150px;
    top: 80%;
    left: 20%;
    transform: rotate(60deg);
  }
  
  /* Add more shape styles as needed */
  .investors-container .square {
    position: fixed;
    z-index: -1;
  }  

  .investors-container .square:nth-child(1) {
    top: 0;
    left: 0;
}
.investors-container .square:nth-child(2) {
    top: 10%;
    left: 0;
}
.pitch-deck-button {
    font-size: 0.9rem;
    padding: 10px 16px;
    gap: 5px
  }

@media (max-width: 950px) {
    .investors-container .feature-list {
       flex-wrap: wrap;
      }

      .download-icon {
        font-size: 1rem;
        margin-right: 6px;
      }

    .Investor-header {
        display: flex;
        flex-direction: column;
      }
      .investors-container .heading-with-icon{
        border-top-left-radius: 0;
      }
      .Investor-header img {
        display: none;
      }

      .investors-container .section h2 {
        font-size: 1.5rem;
      }
      .investors-container {
        padding: 1rem;
       
      }

      .investors-container .funding {
        flex-direction: column-reverse;
        gap: 0
      }
      .investors-container .funding-icon {
        margin: 0;
        width: 50px;
        height: 50px;
        transform: rotate(0deg);
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
      }
}