.preorder-page .reason {
    border: none;
    border-radius: 20px;
}

.preorder-page {
    position: relative;
    padding: 2rem;
    overflow: hidden;
    z-index: 10;
}

.preorder-form {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
}

.preorder-form input {
  margin-bottom: 10px;
}

.faq-iocn-title {
    display: flex;
    height: 30px;
    align-items: center;
}

.faq-iocn-title .icon-wrapper{
    margin-top: 5px;
}

.preorder-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding: 7rem 0 0 0;
  }
  
  .preorder-text,
  .preorder-images {
    flex: 1 1 48%;
    max-width: 48%;
    z-index: 1;

  }
  
  .preorder-images {
    display: flex;
    gap: 10px;
    align-items: flex-start;
  }
  
  .main-image {
    border-radius: 30px;
    width: 100%;
    height: 100%;
  }
  
  .stacked-images {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 35%;
    height: 100%;
  }
  
  .small-image {
    border-radius: 30px;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  .faqs {
    padding: 20px;
    margin-left: 15px;
  }
  
  .faqs h2 {
    margin-bottom: 20px;
  }
  
  .faq-item {
    margin-bottom: 20px;
  }
  
  .faq-item h3 {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: var(--active-color);
  }
  
  .faq-item h3 .icon {
    margin-right: 10px;
    font-size: 1.5rem;
    color: var(--active-color); 
  }
  
  .faq-item p {
    margin-left: 35px; 
    color: #555;
  }

  .faq-item .icon-wrapper {
    background-color: transparent ;
  }
  
  
  
  @media screen and (max-width: 768px) {
    .preorder-text,
    .preorder-images {
      flex: 1 1 100%;
      max-width: 100%;
    }
  
    .preorder-images {
      flex-direction: column;
    }
  
    .main-image {
      max-width: 100%;
      border-radius: 35px;
    }
  
    .stacked-images {
      flex-direction: row;
      justify-content: space-between;
      max-width: 100%;
      margin-top: 20px;
    }
  
    .small-image {
      max-width: 48%;
      border-radius: 35px;
    }
    .preorder-section {
      padding: 5rem 0 2rem 0;
    }
    .faqs {
        padding: 10px;
        margin-left: 10px;
      }
    
      .faq-item h3 {
        font-size: 1rem;
      }
    
      .faq-item h3 .icon {
        font-size: 1.2rem;
      }
    
      .faq-item p {
        margin-left: 20px;
        font-size: 0.9rem;
      }
      .preorder-page {
        padding: 1rem;  
    }
  }
  