.testimonials-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .testimonials-list.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  .testimonials-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    gap: 25px;
    list-style: none;
    justify-content: center;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    width: 100%;
  }
  
  .testimonial-item {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 1.7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--active-color);
    padding: 1rem;
    transition: transform 0.5s ease-out, background-color 0.5s ease-out, color 0.5s ease-out;
    width: 330px;
    transform: scale(0.95);
    transition: transform 0.5s ease;
  }
  
  .testimonial-item.show {
    transform: scale(1);
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .user-image {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    border: 3.4px solid #7b49b1;
  }
  
  .user-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .user-name {
    font-size: 0.875rem;
    font-weight: 600;
    margin: 0;
    color: #653399;
  }
  
  .feedback-time {
    font-size: 0.8rem;
    color: #9ca3af;
    font-weight: 300;
    margin: 0;
    font-style: italic;
  }
  
  .actions-container {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  
  .view-more-btn,
  .close-all-btn {
    background-color: var(--active-color);
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-more-btn:hover,
  .close-all-btn:hover {
    background-color: #653399;
  }
  
  .see-more-link {
    color: var(--active-color);
    padding-top: 4rem;
    text-decoration: underline;
    align-self: flex-end;
  }
  
  .testimonials-section {
    padding: 20px;
  }
  
  .testimonials-section .title {
    font-size: 2rem;
    display: flex;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 20px;
  }

 .testimonial-title::after {
    content: "";
    display: block;
    border-radius: 5px;
    width: 70px; 
    height: 3px;  
    background-color: var(--active-color); 
    margin: 8px 0;
  }
  
  .testimonials-section .subtitle {
    margin-bottom: 40px;
    position: relative;
    z-index: 2;
  }
  
  .view-more-btn:hover,
  .close-all-btn:hover {
    background-color: #4b0082; 
  }
  
  .action-btn {
    border: 1px solid var(--active-color);
    background: none;
    color: var(--active-color);
    font-size: 12px;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 1rem;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .action-btn span {
    margin-left: 0.5rem;
  }
  
  .action-btn:hover {
    background-color: var(--active-color);
    color: white;
  }
  
  .actions-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  @media (max-width: 780px) {

    .testimonial-item.show{
      margin: 0px !important;
      width: 100%;
    }
   }