.certificate-timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 40px 0;
}

.certificate-timeline-container::after {
    background-color: #653399;
    content: '';
    position: absolute;
    left: calc(50% - 2px);
    width: 10px;
    height: 100%;
    border-radius: 8px;
}

.certificate-timeline-item {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    position: relative;
    margin: 10px 0;
    width: 50%;
}

.certificate-timeline-item:nth-child(odd) {
    align-self: flex-end;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 0;
}

.certificate-timeline-item-content {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 1rem 2rem ;
    position: relative;
    width: 400px;
    max-width: 70%;
    background-color: #e6e6fa;
}

/* Remove the arrow pointing to the timeline line */
.certificate-timeline-item-content::after {
    content: none;
}

.certificate-timeline-item-content .tag {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    top: 5px;
    left: 5px;
    letter-spacing: 1px;
    padding: 5px;
    position: absolute;
    text-transform: uppercase;
}

.certificate-timeline-item:nth-child(odd) .certificate-timeline-item-content .tag {
    left: auto;
    right: 5px;
}

.certificate-timeline-item-content time {
   display: flex;
   gap:5px;
    color: #653399;
    font-weight: bold;
}

.certificate-timeline-item-content p {
    font-size: 18px;
    line-height: 18px;
    margin: 15px 0;
}

@media only screen and (max-width: 1023px) {
    .certificate-timeline-item {
        justify-content: flex-start;
        padding-left: 0px;
        width: 100%;
    }

    .certificate-timeline-item:nth-child(odd) {
        padding-left: 0;
    }

    .certificate-timeline-container::after {
        left: 0px; 
    }

    .certificate-timeline-item-content {
        max-width: 100%;
        text-align: left;
        align-items: flex-start;
    }
    .certificate-timeline-item:nth-child(odd) {
        align-self: flex-start;
    }
}
