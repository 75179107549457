/* Style for big shape elements */
.big-shape {
  width: 25vw; /* Adjusted size to prevent overflow */
  height: 25vw; /* Maintain square shape */
  background-color: var(--navbar-shape-color); /* Use shape color */
  border-radius: 10%; /* Rounded corners */
  margin: 10px;
  opacity: 0.2;
  z-index: -1;
  position: absolute; /* Position shapes absolutely within the container */
  transform: rotate(45deg); /* Rotate shapes */
  transition: transform 0.5s ease, opacity 0.5s ease; /* Smooth animation */
}

.big-shape:nth-child(1) {
  top: 10%;
  left: 10%;
}

.big-shape:nth-child(2) {
  top: 10%;
  right: 10%;
}

.big-shape:nth-child(3) {
  bottom: 10%;
  left: 10%;
}

.big-shape:nth-child(4) {
  bottom: 10%;
  right: 10%;
}

.big-shape:nth-child(5) {
  top: 50%;
  left: 20%;
}

.big-shape:nth-child(6) {
  bottom: 20%;
  right: 30%;
}

.big-shape:nth-child(7) {
  top: 70%;
  left: 60%;
}