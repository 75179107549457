:root {
    --active-color: #7b49b1;
    --navbar-bg-color: #e6e6fa;
    --navbar-shape-color: rgb(200, 156, 247);
    --outer-square-color: #e6e6fa;
    --inner-square-color: #653399;
    --shape-color: rgb(69, 67, 195, 0.5);
  }
  
  .featured-big-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }

  .last-feature {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    align-items: center;
    gap: 10px;
  }

  .featured-image {
    width: 40%;
    z-index: 2;
    border-radius: 20px;
  }
  .featured-section {
    padding: 40px;
    border-radius: 10px;
    margin: 40px auto;
    position: relative; 
  }
  
  .featured-heading {
    color: var(--active-color);
    position: relative;
    z-index: 2;
    padding: 20px 0;
  }

  .featured-heading::after {
    content: "";
    display: block;
    border-radius: 5px;
    width: 120px; 
    height: 3px;  
    background-color: var(--active-color); 
    margin: 8px 0;
  }
  
  .featured-icon {
    font-size: 2.5rem;
    color: var(--active-color);
  }

  .featured-description {
    margin-bottom: 40px;
    position: relative;
    z-index: 2;
  }
  
  .feature-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    z-index: 2;
  }

  .top-feat {
    display: flex;
    gap: 50px;
  }
  
  .feature-item {
    border-radius: 18px;
    border: 1px solid var(--active-color);
    padding: 10px;
    background: #fff;
  }
  
  .feature-title {
    color: var(--inner-square-color);
    margin-top: 10px;
  }
  
  .feature-description {
    font-size: 1rem;
    color: #555;
  }
  
  /* Decorative Shapes */
  .featured-section .shapes-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden; /* Ensure shapes don’t overflow the section */
  }
  
  .shape {
    position: absolute;
    background-color: var(--shape-color);
    opacity: 0.5; /* Semi-transparent shapes */
  }
  
  /* Rectangles */
  .rectangle-1 {
    width: 150px;
    height: 50px;
    top: 10%;
    left: 10%;
    transform: rotate(20deg);
  }
  
  .rectangle-2 {
    width: 120px;
    height: 40px;
    top: 30%;
    right: 10%;
    transform: rotate(-10deg);
  }
  
  .rectangle-3 {
    width: 80px;
    height: 30px;
    bottom: 20%;
    left: 20%;
    transform: rotate(15deg);
  }
  
  .rectangle-4 {
    width: 100px;
    height: 50px;
    bottom: 10%;
    right: 25%;
    transform: rotate(-25deg);
  }
  
  .rectangle-5 {
    width: 70px;
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(30deg);
  }
  
  .rectangle-6 {
    width: 90px;
    height: 40px;
    bottom: 40%;
    right: 40%;
    transform: rotate(45deg);
  }
  
  .rectangle-7 {
    width: 110px;
    height: 50px;
    top: 60%;
    left: 60%;
    transform: rotate(-35deg);
  }
  
  /* Circles */
  .circle-1 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    top: 15%;
    left: 70%;
  }
  
  .circle-2 {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    bottom: 25%;
    right: 15%;
  }
  
  /* Triangle */
  .triangle-1 {
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-bottom: 60px solid var(--shape-color);
    top: 75%;
    left: 25%;
    transform: rotate(45deg);
  }


  @media (max-width: 950px) {
    .featured-big-container {
        flex-direction: column;
    }
    .last-feature {
      display: block;
    }
    .featured-image {
        width: 100%;
    }
    .featured-section {
        padding: 20px;
    }
  }

  @media (max-width: 670px) {
    .top-feat {
        flex-direction: column;
        gap: 20px;
    }
  }