.contact-section {
    padding: 2rem;
    padding-top: 40px;
  }
  
  .contact-details {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 2rem;
    padding-top: 80px;
    margin: 40px;
  }

  .contact-content {
    max-width: 40%;
  }
  
  .contact-content h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .contact-content p {
    margin: 0.5rem 0;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 10px;
  }

  .contact-form-container {
    flex: 1;
    max-width: 45%;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form label {
    margin-top: 1rem;
    font-weight: bold;
    display: flex;
    gap: 10px;
    color: #4b0082;
  }
  
  .contact-form input,
  .contact-form textarea {
    border-radius: 12px;
    height: 30px; 
    margin-top: 0.5rem;
    padding: 1.3rem;
    border: 1px solid #ccc;
    outline: none;
    font-family: sans-serif;
    font-size: 1rem;
  }
  
  .contact-form textarea {
    height: 100px;
  }
  
  .contact-form button {
    margin-top: 20px;
    padding: 20px 30px;
    background-color: var(--active-color);
    color: white;
    font-size: 16px;
    letter-spacing: 1.5px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
  }
  
  .contact-form button:hover {
    background-color: #4b0082; /* Darker shade on hover */
  }

  .contact-content h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    position: relative;
  }
  
  @media (max-width: 768px) {
    .contact-details {
      align-items: center;
      flex-direction: column-reverse;
      margin: 20px;
    }
    .contact-content,
    .contact-form-container {
      max-width: 100%;
      width: 100%;
    }

  .contact-item-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
     
  }
  
