:root {
    --active-color: #653399;
    --navbar-bg-color: #e6e6fa; 
  }

  .close-icon{ display: none;}
  
  .navbar {
    position: fixed;
    top: 5px;
    left: 10px;
    right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: transparent; 
    z-index: 1000;
    transition: background-color 0.3s ease; 
    border-radius: 12px; 
  }
  
  .navbar.scrolled {
    background-color: var(--navbar-bg-color); 
  }
  
  .nav-content {
    display: flex;
    justify-content: center; 
    flex: 1; 
  }
  
  .logo img{
    height: 35px;
    padding-top: 10px;
  }
  
  .nav-links {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .nav-item {
    margin-left: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.775rem;
    line-height: 1.75;
    text-transform: uppercase;
    position: relative;
  }
  
  .nav-link {
    text-decoration: none;
    color: black;
    position: relative;
    padding: 5px 0;
  }
  
  .nav-link::after {
    content: '';
    display: block;
    width: 0%; 
    height: 3px;
    background-color: var(--active-color);
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: width 200ms ease-in-out;
  }
  
  .nav-link:hover::after {
    width: 70%;
  }
  
  .active-link {
    color: var(--active-color);
  }
  
  .register-button {
    padding: 10px 20px;
    background-color: var(--active-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  
  .register-button:hover {
    background-color: var(--active-color);
  }
  
  .close-icon,
  .hamburger {
    display: none;
    font-size: 2.5rem;
    font-weight: 600;
    border-radius: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--active-color);

  }

  .nav-item a {
    text-decoration: none;
  }
  
  @media (max-width: 950px) {
    .navbar {
      left: 10px;
      right: 10px;
      top: 10px;
    }
  
    .nav-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  
    .nav-links {
      display: none;
      flex-direction: column;
      position: fixed;
      top: 2px;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: var(--navbar-bg-color);
      border-radius: 5px;
      padding: 0;
      overflow: hidden;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transform: translateX(100%);
      transition: transform 0.3s ease;
      z-index: 999;
      align-items: center;
      justify-content: center;
    }
  
    .nav-links.open {
      display: flex;
      transform: translateX(0);
    }
  
    .nav-item {
      margin: 20px 0;
    }
  
    .logo img{
      text-align: center;
      margin: 0;
      cursor: pointer;
      padding-left: 5px;
    }
  
    .hamburger {
      display: block;
      width: 50px;
      padding: 0;
      margin: 0;
    }
  
    .register-button {
      margin-left: auto;
      display: block;
      border-radius: 10px;
    }
  
    .close-icon {
        display: flex;
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 2.5rem;
      background: none;
      border: none;
      cursor: pointer;
      transition: opacity 0.3s ease;
    }
  
    .close-icon:hover {
      opacity: 0.7;
    }
    
    .nav-links.open .close-icon {
      animation: fadeIn 0.3s ease;
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateX(-10px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  
  @media (min-width: 401px) and (max-width: 700px) {
    .heading {
      font-size: 1.2rem;
    }
  
    .description {
      font-size: 0.9rem;
    }
  
    .pre-order-button,
    .pre-Invest-button {
      padding: 10px;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 400px) {
    .heading {
      font-size: 1rem;
    }
    .navbar {
      left: 0px;
      right: 0px;
      top: 0px;
      padding: 0 10px 0 0;
      border-radius: 0;

  }
    .description {
      font-size: 1rem;
    }
  
    .pre-order-button,
    .pre-Invest-button {
      padding: 8px;
      font-size: 0.8rem;
    }
  
    .image-section {
      margin-top: 10px;
    }
  }