.solutions .icon {
    font-size: 1rem;
    color: #ceaff0;
    transform: rotate(-45deg);
}

.solutions-slider {
 position: relative;
 width: 100%;
 height: auto;
 overflow: hidden;
}

.ecs {
    margin: 50px 0;
}

.solutions main {
    padding: 40px;
}
.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    margin-top: 50px;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.solutions-image-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 40px;
    margin-top: 40px;
    justify-content: center;
  }
  
  .responsive-image {
    width: 40%;
    object-fit: cover;
  }

  .fd h3 {
    padding-bottom: 5px;
  }

  .fd .feature-title {
    margin-top: 0;
  }

  .fd .feature-item {
    padding: 15px;
  }
  


  @media (max-width: 768px) {
    .solutions-slider {
        height: auto;
       }
       .ecs {
        margin: 20px 0;
    }
    .solutions main {
        padding: 20px;
    }
    .responsive-image {
        width: 100%;
      }
      
    .solutions-image-container {
        flex-wrap: wrap;
        margin-top: 20px;
      }
    
}