@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  color: rgba(0, 0, 0, 0.6);
}

a {
  all: unset;
  cursor: pointer;
}
