.banner-container {
    height: 50vh;
    background-color: #e6e6fa;
    padding: 10px;
    overflow: hidden;
    position: relative;
}

.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: 20px;
}

.banner p {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
}

@media (max-width: 768px) {
    .banner p {
        width: 90%;
    }
}


