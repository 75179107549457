.content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
  }
  
  .slide-image {
    max-width: 50%;
    max-height: 70vh;
  }

  .slick-dots {
    position:unset;
  }
  
  .slide-content {
    max-width: 45%;
    padding-left: 20px;
  }
  
  .slick-slide h3 {
    font-size: 24px;
    margin: 10px 0;
  }
  
  .slick-slide p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .content-wrapper {
      flex-direction: column;
      text-align: center;
    }
  
    .slide-image {
      max-width: 60%;
      margin-bottom: 20px;
    }
  
    .slide-content {
      max-width: 100%;
    }
  }
  