:root {
    --active-color: #7b49b1;
    --navbar-bg-color: #e6e6fa;
    --navbar-shape-color: rgb(200, 156, 247);
    --outer-square-color: #e6e6fa; /* Background color of the outer square */
    --inner-square-color: var(--active-color); /* Background color of the inner square */
    --shape-color: #f0f0f0; /* Color of the hanging shapes */
  }

  .home-container {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Prevent overflow */
  }
  .home-container .heading-container  {
    width: 60%;
  }
  .content {
    background-color: var(--navbar-bg-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding-left: 20px;
    padding-right: 20px;
    height: 100vh; /* Full viewport height */
    position: relative;
    z-index: 1; /* Ensure content is above shapes */
  }
  
  .text-section {
    text-align: left;
    margin-bottom: 20px;
  }

  h1, h2, h3, h4 {
    color: var(--active-color);
  }

  .heading-container {
    z-index: 3;
    margin-left: 40px;
  }
  
  .heading {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .home-buttons {
    display: flex;
    gap: 20px;
  }
  
  .description {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 32px;
    line-height:40px;
    font-size: 1.2rem;
  }

  .small-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .pre-Invest-button {
    padding: 19px 29px;
    background: transparent;
    color: var(--active-color);
    font-size: 16px;
    border: 2px solid var(--active-color);
    letter-spacing: 1.5px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
  }

  .pre-Invest-button:hover {
    background-color: var(--active-color);
    color: #fff;
  }
  
  .image-section{
    z-index: 2;
      }
  
  .image {
    height: 70vh;
    object-fit: cover;
  }
  
  .double-rectangle {
    position: absolute;
    top: -25%; /* Adjust top position to keep it within view */
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 50vw;  /* 50% of viewport width */
    height: 50vh; /* 50% of viewport height */
    background: var(--navbar-shape-color);
    opacity: 0.3;
    border-radius: 50px;
  }
  
  
  .additional-squares {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 30%; /* 30% of viewport height */
    z-index: 0; /* Ensure squares are behind the content */
  }
  
  .square {
    width: 25vw; /* Adjusted size to prevent overflow */
    height: 25vw; /* Maintain square shape */
    background-color: var(--navbar-shape-color); /* Use shape color */
    border-radius: 10%; /* Rounded corners */
    margin: 10px;
    opacity: 0.3;
    position: absolute; /* Position squares absolutely within the container */
    transform: rotate(45deg); /* Rotate squares */
  }
  
  .square:nth-child(1) {
    top: 10%;
    left: 10%;
  }
  
  .square:nth-child(2) {
    top: 10%;
    right: 10%;
  }
  
  .square:nth-child(3) {
    bottom: 10%;
    left: 10%;
  }
  
  .square:nth-child(4) {
    bottom: 10%;
    right: 10%;
  }
  
  /* Wave-like shape at the bottom with animation */
  .wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px; /* Adjust height as needed */
    background: var(--navbar-shape-color);
    clip-path: polygon(0% 100%, 10% 85%, 20% 90%, 30% 85%, 40% 90%, 50% 85%, 60% 90%, 70% 85%, 80% 90%, 90% 85%, 100% 100%);
    z-index: -1; /* Ensure it is behind the content */
  }

  .pre-order-button {
    padding: 20px 30px;
    background-color: var(--active-color);
    color: white;
    font-size: 16px;
    letter-spacing: 1.5px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
  }
  .pre-order-button:hover {
    background-color: darkviolet;
  }
.hanging-Mission-section p {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);

}

.hanging-mission-section {
    position: relative;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin: 80px auto auto auto;
    max-width: 600px;
  }
  
  .mission-heading {
    width: 250px;
    font-size: 2rem;
    margin-bottom: 10px;
    position: relative;
  }
  
  .mission-paragraph {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .shape {
    position: absolute;
    background-color: var(--active-color);
    border-radius: 30%;
    z-index: -1;
    opacity: 0.4;
  }
  
  .shape.small {
    width: 60px;
    height: 30px;
    z-index: 2;
  }
  
  .shape.medium {
    width: 90px;
    height: 55px;
  }
  
  .shape.large {
    width: 35px;
    height: 35px;
  }
  
  .shape.top-left {
    top: -10px;
    left: -10px;
  }
  
  .shape.bottom-right {
    bottom: -10px;
    right: -10px;
  }
  
  .shape.bottom-left {
    bottom: -15px;
    left: 20px;
  }
  
.mission-heading::after {
    content: "";
    display: block;
    border-radius: 5px;
    width: 70px; 
    height: 3px;  
    background-color: var(--active-color); 
    margin: 8px 0;
  }

 .hanging-Mission-section {
    display: flex;
    padding: 10px 50px;
    flex-direction: column;
    margin: 50px 20px;
 }

  @media (max-width: 950px) {
    .image-section img {
      position: absolute;
      bottom: 0px;
      right: 0px;
      opacity: 0.7;
    }
    .text-section {
      margin-bottom: 0;
    }
    .small-content {
        padding-top: 70px;
        padding: 20px;
        flex-direction: column;
        gap: 0;
    }
    .heading-container {
        margin: 30px 0px 0px 0px;
    }
    .home-buttons {
        flex-direction: column;
    }
    .pre-order-button,
    .pre-Invest-button {
        font-size: 12px;
        width: 100%;
        height: 50px;
    }
    .heading-container {
        width: 100% !important; 
    }
    
    @media (min-width: 768px) {
      .testimonials-section {
        padding-left: 60px;
        padding-right: 60px;
      }
     
    }
    
   .hanging-mission-section {
       
        margin: 80px 20px 20px 20px;
    }
    
    .heading {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 16px;
    }
    
    .description {
        font-size: 15px;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 32px;
        line-height: 20px;
    }
  }