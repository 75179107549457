.footer-container {
  padding: 5rem 2rem 2rem 2rem;
  background-color: #f8f9fa;
  color: #333;
}

.useful-links-head {
  padding-bottom: 10px;
}

.footer-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}

.footer-column {
  display: flex;
  justify-content: center;
  min-width: 220px;
  flex-direction: column;
}

.footer-logo {
  width: 150px;
  margin-bottom: 1rem;
}

.footer-column h4 {
  font-size: 1.2rem;
  margin: 0;
}

.Footer-navlist {
  display: flex;
  flex: 1 1;
  gap: 50px;
}

.footer-column p,
.footer-column a {
  margin: 0.5rem 0;
  font-size: 0.875rem;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 0.75rem;
  position: relative;
}

.footer-column ul li a {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
  transition: border-color 0.3s ease, color 0.3s ease;
}

.footer-column ul li a:hover {
  border-color: #653399;
  color: #653399;
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.social-icon {
  font-size: 1.2rem !important;
  display: flex;
  color: #fff !important;
  padding: 12px;
  align-items: center;
  border-radius: 50%;
  background: #653399;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #653399;
  background-color: #EB4913;
  transition: 0.3s;
}

.footer-bottom {
  margin-top: 2rem;
  text-align: center;
}

.footer-divider {
  width: 90%;
  margin: 0 auto 1rem;
  border-top: 1px solid #ddd;
}

.footer-bottom p {
  padding-top: 10px;
  font-size: 0.875rem;
  margin: 0;
  color: #777;
}

.contact-info {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 5px;
}

.contact-icon {
  color: var(--active-color);
  font-size: 1.2rem;
}
@media (max-width: 768px) {
  .contact-item {
    display: flex;
  }
.footer-columns {
  flex-direction: column;
  align-items: center;
}

.Footer-navlist {
  gap: 30px;
}

.footer-column {
  align-items: center;
  text-align: center;
}

.social-icons {
  flex-direction: row; 
  justify-content: center;
}

.footer-logo {
  margin-bottom: 1.5rem;
}

.footer-column ul {
  text-align: center;
}
}